import React, { useState } from 'react';
import './App.css';
import Signin from './scenes/auth/signin';
import Signup from './scenes/auth/signup';
import { Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/routes/protected-route';
import ForgetPassword from './scenes/auth/forget-password';
import ResetPassword from './scenes/auth/reset-password';
import Dashboard from './scenes/app/dashboard';
import Header from './components/header';
import history from './utils/history';
import NotVerified from './scenes/auth/not-verified';
import VerifyAccount from './scenes/auth/verify-account';
import NotAuthRoute from './components/routes/not-auth-route';
import VerifiedRoute from './components/routes/verified-route';
import { getUser } from './utils';
import Profile from './scenes/app/profile/profile';
import CaseDetails from './scenes/app/caseDetails/caseDetails';
import AddCase from './scenes/app/addCase/addCase';
import EditProfile from './scenes/app/edit-profile';
import { Redirect, useLocation } from 'react-router';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core';
import { getThemeByName } from './styles/base';
import { AlertMeassageType } from './types/index';
import Registries from './scenes/app/registries/Registries';
import RegistryDetails from './scenes/app/RegistryDetails/RegistryDetails';
import RegistryForm from './scenes/app/registryForm/RegistryForm';
import AddCaseRegistry from './scenes/app/addCaseRegistry/addCaseRegistry';
import Invitation from './scenes/app/invitation/Invitation';
import HomePage from './scenes/app/homePage/HomePage';
import ContactUs from './scenes/app/contactUs/ContactUs';
import Plans from './scenes/app/plans/plans';
import VerifyRegistrySharing from './scenes/auth/verifyRegistry/verifyRegistry';
import AboutUsPage from './scenes/app/aboutUsPage/AboutUsPage';
import CurrentPlan from './scenes/app/currentPlan/CurrentPlan';
import Terms from './scenes/app/terms/terms';
import Privacy from './scenes/app/privacy/privacy';
import EditRegistry from './scenes/app/editRegistry/EditRegistry';
import RVUCalculator from './scenes/app/rVUCalculator/RVUCalculator';
import Users from './scenes/app/users/Users';
import VerifyInvitation from './scenes/auth/verifyInvitation/VerifyInvitation';
import OurTeam from './scenes/app/ourTeam/OurTeam';
import HippaSecurity from "./scenes/app/hippaSecurity/hippaSecurity";
import NewHeader from './components/NewHeader';

export const ThemeContext = React.createContext((themeName) => { });

const App = (props) => {
  const { setTheme } = props;
  const [user, setUser] = React.useState(getUser());
  const [authenticated, setAuthenticated] = React.useState(false);
  const [executed, setexecuted] = React.useState(false);
  const [themeName, _setThemeName] = React.useState(
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'darkTheme'
      : 'lightTheme'
  );
  const [showEditCaseMessage, setShowEditCaseMessage] = React.useState(false);
  const [caseMessage, setCaseMessage] = React.useState([]);
  const [showVerifyMessage, setShowVerifyMessage] = React.useState(false);
  const [verifyMessage, setVerifyMessage] = React.useState([]);
  const [showAddCaseRegistryMessage, setShowAddCaseRegistryMessage] =
    React.useState(false);
  const [addCaseRegistryMessage, setAddCaseRegistryMessage] = React.useState(
    []
  );
  const [showAddRegistryMessage, setShowAddRegistryMessage] =
    React.useState(false);
  const [registryMessage, setRegistryMessage] = React.useState('');
  const [registryMessageStatus, setRegistryMessageStatus] = React.useState('');
  const [editUserName, setEditUserName] = React.useState(false);
  const [isHandledInvitation, setIsHandledInvitation] = React.useState(false);
  const [userAccess, setUserAccess] = React.useState('VIEW');
  const [openDashboardTab, setOpenDashboardTab] = React.useState(false);
  const [viewAfterhandled, setViewAfterhandled] = React.useState('INVITATIONS');
  React.useEffect(() => {
    let mounted = true;
    if (user && mounted) {
      if (user.id) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    } else {
      setAuthenticated(false);
    }
    return () => {
      mounted = false;
    };
  }, [user]);
  React.useEffect(() => {
    localStorage.setItem('Theme', themeName);
  }, [themeName]);

  const theme = getThemeByName(themeName);

  return (
    <ThemeContext.Provider value={_setThemeName}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
          <AppHeader
            user={user}
            setUser={setUser}
            _setThemeName={_setThemeName}
            themeName={themeName}
            editUserName={editUserName}
            setEditUserName={setEditUserName}
            isHandledInvitation={isHandledInvitation}
            setIsHandledInvitation={setIsHandledInvitation} />
          <Switch>
            <NotAuthRoute
              path="/"
              exact
              component={HomePage}
              authenticated={authenticated}
            />
            <NotAuthRoute
              path="/Contact"
              exact
              component={ContactUs}
              authenticated={authenticated}
            />
            <NotAuthRoute
              path="/OurTeam"
              exact
              component={OurTeam}
              authenticated={authenticated}
            />
            <NotAuthRoute
              path="/about"
              exact
              component={AboutUsPage}
              authenticated={authenticated}
            />
            <NotAuthRoute
              exact
              path="/Plans"
              component={Plans}
              authenticated={authenticated}
            />
            <NotAuthRoute
              exact
              path="/forget-password"
              component={ForgetPassword}
            />
            <NotAuthRoute
              exact
              path="/reset-password/:email"
              component={ResetPassword}
            />
            <NotAuthRoute
              exact
              path="/signup"
              component={(props) => <Signup setUser={setUser} activeStep={0} />}
            />
            <NotAuthRoute
              exact
              path="/signup/:planId"
              component={(props) => <Signup setUser={setUser} activeStep={0} />}
            />
            <VerifiedRoute path="/not-verified" component={NotVerified} />
            <Route
              path="/registries/:registryId/verification/:code"
              component={(props) => (
                <VerifyRegistrySharing
                  executed={executed}
                  setexecuted={setexecuted}
                  setCaseMessage={setCaseMessage}
                  setShowEditCaseMessage={setShowEditCaseMessage}
                  setShowVerifyMessage={setShowAddCaseRegistryMessage}
                  setVerifyMessage={setAddCaseRegistryMessage}
                />
              )}
            />
            <Route
              path="/dashboard/:doctorId/invitation/:code/:key"
              component={(props) => (
                <VerifyInvitation
                  setOpenDashboardTab={setOpenDashboardTab}
                  setEditUserName={setEditUserName}
                />
              )}
            />
            <Route path="/verification/:token" component={VerifyAccount} />
            <ProtectedRoute
              exact
              path="/dashboard"
              component={(props) => (
                <Dashboard
                  title="dashboard"
                  registryId={0}
                  caseMessage={caseMessage}
                  showEditCaseMessage={showEditCaseMessage}
                  setShowEditCaseMessage={setShowEditCaseMessage}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/shared-dashboard/:doctorId"
              component={(props) => (
                <Dashboard
                  title="sharedDashboard"
                  registryId={0}
                  caseMessage={caseMessage}
                  showEditCaseMessage={showEditCaseMessage}
                  setShowEditCaseMessage={setShowEditCaseMessage}
                  setUserAccess={setUserAccess}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/users"
              component={(props) => <Users />}
            />
            <ProtectedRoute
              exact
              path="/profile"
              component={(props) => (
                <Profile
                  setEditUserName={setEditUserName}
                  setIsHandledInvitation={setIsHandledInvitation}
                  isHandledInvitation={isHandledInvitation}
                  openDashboardTab={openDashboardTab}
                  setOpenDashboardTab={setOpenDashboardTab}
                  setViewAfterhandled={setViewAfterhandled}
                  viewAfterhandled={viewAfterhandled}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/RVU-calculator"
              component={RVUCalculator}
            />
            <ProtectedRoute
              exact
              path="/edit-profile"
              component={(props) => <EditProfile />}
            />
            <ProtectedRoute exact path="/MyPlan" component={CurrentPlan} />
            <ProtectedRoute
              exact
              path="/case/:caseId"
              component={(props) => (
                <CaseDetails
                  showEditCaseMessage={showEditCaseMessage}
                  setShowEditCaseMessage={setShowEditCaseMessage}
                  setCaseMessage={setCaseMessage}
                  caseMessage={caseMessage}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/shared-case/:doctorId/:caseId"
              component={(props) => (
                <CaseDetails
                  showEditCaseMessage={showEditCaseMessage}
                  setShowEditCaseMessage={setShowEditCaseMessage}
                  setCaseMessage={setCaseMessage}
                  caseMessage={caseMessage}
                  userAccess={userAccess}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/registrycase/:registryId/:caseId"
              component={(props) => (
                <CaseDetails
                  showEditCaseMessage={showEditCaseMessage}
                  setShowEditCaseMessage={setShowEditCaseMessage}
                  setCaseMessage={setCaseMessage}
                  caseMessage={caseMessage}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/new-registry"
              component={(props) => (
                <RegistryForm
                  showAddRegistryMessage={showAddRegistryMessage}
                  registryMessage={registryMessage}
                  setRegistryMessageStatus={setRegistryMessageStatus}
                  setShowAddRegistryMessage={setShowAddRegistryMessage}
                  setRegistryMessage={setRegistryMessage}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/new-case"
              component={(props) => (
                <AddCase
                  showEditCaseMessage={showEditCaseMessage}
                  setShowEditCaseMessage={setShowEditCaseMessage}
                  setCaseMessage={setCaseMessage}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/new-shared-case/:doctorId"
              component={(props) => (
                <AddCase
                  showEditCaseMessage={showEditCaseMessage}
                  setShowEditCaseMessage={setShowEditCaseMessage}
                  setCaseMessage={setCaseMessage}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/registry/Invitation/:registryId"
              component={(props) => <Invitation />}
            />
            <ProtectedRoute
              exact
              path="/new-Case-registry/:registryId"
              component={(props) => (
                <AddCaseRegistry
                  showAddCaseRegistryMessage={showAddCaseRegistryMessage}
                  setShowAddCaseRegistryMessage={setShowAddCaseRegistryMessage}
                  setAddCaseRegistryMessage={setAddCaseRegistryMessage}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/edit-case/:caseId"
              component={(props) => (
                <AddCase
                  showEditCaseMessage={showEditCaseMessage}
                  setShowEditCaseMessage={setShowEditCaseMessage}
                  setCaseMessage={setCaseMessage}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/edit-shared-case/:doctorId/:caseId"
              component={(props) => (
                <AddCase
                  showEditCaseMessage={showEditCaseMessage}
                  setShowEditCaseMessage={setShowEditCaseMessage}
                  setCaseMessage={setCaseMessage}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/edit-Registry-case/:registryId/:caseId"
              component={(props) => (
                <AddCaseRegistry
                  setShowEditCaseMessage={setShowEditCaseMessage}
                  setCaseMessage={setCaseMessage}
                  showAddCaseRegistryMessage={showEditCaseMessage}
                  setShowAddCaseRegistryMessage={setShowEditCaseMessage}
                  setAddCaseRegistryMessage={setCaseMessage}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/edit-registry/:registryId"
              component={(props) => <EditRegistry />}
            />

            <ProtectedRoute
              exact
              path="/registries"
              component={(props) => (
                <Registries
                  showAddRegistryMessage={showAddRegistryMessage}
                  registryMessage={registryMessage}
                  setShowAddRegistryMessage={setShowAddRegistryMessage}
                  setRegistryMessage={setRegistryMessage}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/registry/:registryId"
              component={(props) => (
                <RegistryDetails
                  registryMessage={registryMessage}
                  showAddRegistryMessage={showAddRegistryMessage}
                  setShowAddRegistryMessage={setShowAddRegistryMessage}
                  addCaseRegistryMessage={addCaseRegistryMessage}
                  showAddCaseRegistryMessage={showAddCaseRegistryMessage}
                  setShowAddCaseRegistryMessage={setShowAddCaseRegistryMessage}
                  setRegistryMessage={setRegistryMessage}
                />
              )}
            />

            <NotAuthRoute
              exact
              path="/signin"
              component={(props) => (
                <Signin
                  setUser={setUser}
                  activeStep={0}
                  setEditUserName={setEditUserName}
                  isHandledInvitation={isHandledInvitation}
                  setIsHandledInvitation={setIsHandledInvitation}
                />
              )}
            />

            <NotAuthRoute
              exact
              path="/privacy"
              component={(props) => <Privacy />}
            />

            <NotAuthRoute
              exact
              path="/terms"
              component={(props) => <Terms />}
            />

            <NotAuthRoute
              exact
              path="/hippa_security"
              component={(props) => <HippaSecurity />}
            />

            <Redirect from="**" to={'/dashboard'} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default App;

const AppHeader = ({ user, setUser, _setThemeName, themeName, editUserName, setEditUserName, isHandledInvitation, setIsHandledInvitation }) => {
  const location = useLocation().pathname;

  const isProfile = location.includes('profile');
  const isDashboard = location.includes('dashboard');

  React.useEffect(() => {
    if(isProfile || isDashboard){
      setUser(getUser());
    }
  }, []);

  return (
    <>
      {user?.id > 0 || user?.verified || isProfile || isDashboard ? (<Header
        setUser={setUser}
        setTheme={_setThemeName}
        theme={themeName}
        editUserName={editUserName}
        setEditUserName={setEditUserName}
        isHandledInvitation={isHandledInvitation}
        setIsHandledInvitation={setIsHandledInvitation}
      ></Header>) : <NewHeader />}
    </>
  )
}