import {
  Box,
  Button,
  Dialog,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import PaymentStyle from './PlanStyles';
import * as api from '../../services/apiService';
import BillingDialog from './BillingDialog';
import DeleteDialog from './DeleteDialog';
import { Alert, Color } from '@material-ui/lab';
import { getUser } from '../../utils';

const UpgradeDialog = (props) => {
  const {
    isOpen,
    onClose,
    setNewPlan,
    subscriptionId,
    userToken,
    handleCloseDialog,
    getPendingSubscription
  } = props;

  const classes = PaymentStyle();
  const [planName, setPlanName] = useState<string>('');
  const [currentPlan, setCurrentPlan] = useState<any>();
  const [isOpenBilling, setisOpenBilling] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [activeCode, setActiveCode] = useState<boolean>(false);
  const [newPrice, setNewPrice] = useState<number>(0);
  const [selectedPlan, setSelectedPlan] = useState<any>();
  const [isChanged, setIsChanged] = React.useState<boolean>(false);
  const [isChangedPlan, setIsChangedPlan] = React.useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [monthlyPlans, setMonthlyPlans] = useState<any[]>([]);
  const [annualPlans, setAnnualPlans] = useState<any[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertMsg, setAlertMsg] = React.useState<string>('');
  const [alertType, setAlertType] = React.useState<string>('success');

  const handleChangeRadioButton = (e) => {
    if (value === 'Monthly') {
      setValue('Annual');
    } else {
      setValue('Monthly');
    }
  };
  const handleCloseBillingDialog = () => {
    setisOpenBilling(false);
    setCode('');
    setNewPrice(currentPlan?.cost);
    setIsChangedPlan(false);
    setSelectedPlan(undefined);
  };

  const handleOpenBillingInfo = () => {
    setisOpenBilling(true);
  };

  const handleChange = (event) => {
    setPlanName(event.target.value);
    setIsChangedPlan(true);
  };
  const getPlans = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getPlans();
    if (statusCode === api.status.SUCCESS) {
      setMonthlyPlans(data.monthly);

      setAnnualPlans(data.annual);
    }
  }, []);
  const getCurrentPlan = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getCurrentPlan();
    if (statusCode === api.status.SUCCESS) {
      setCurrentPlan(data);
      setValue(data.period);
    }
  }, []);

  const validateCoupon = React.useCallback(async (code, price, flow) => {
    const { data, status_code: statusCode } = await api.validateCoupon(
      code,
      price,
      flow
    );
    if (statusCode === api.status.SUCCESS) {
      setActiveCode(true);
      setNewPrice(data.new_price);
    } else if (statusCode === api.status.BAD_REQUEST) {
      setActiveCode(false);
      setNewPrice(price);
    }
  }, []);

  useEffect(() => {
    if (value) {
      getPlans();
    }
  }, [value]);

  useEffect(() => {
    if (isOpen) {
      getCurrentPlan();
    }
  }, [isOpen]);

  useEffect(() => {
    if (code.length > 0) {
      if (selectedPlan) {
        validateCoupon(code, selectedPlan?.cost, 'normalUser');
      } else {
        validateCoupon(code, currentPlan?.cost, 'normalUser');
      }
    }
  }, [code, selectedPlan]);

  const handleCancelMembership = async () => {
    const user = getUser();
    const subscriptionId = user?.subscription?.subscr_id || user.id;
    try {
      setIsLoading(true);
      const { data, status_code: statusCode } = await api.cancelSubscription(subscriptionId);

      if (statusCode == 200) {
        setAlertType('success');
        setAlertMsg('You have successfully canceled your membership');
        setIsDialogOpen(false);
        handleCloseDialog();
        setIsLoading(false);
        setShowAlert(true);
        setNewPlan(true);

        if (getPendingSubscription) {
          getPendingSubscription();
        }

        setTimeout(() => {
          setAlertMsg('');
          setShowAlert(false);
        }, 3000);
      }
    } catch (error: any) {
      setAlertType('error');
      setAlertMsg('Something went wrong');
      setIsDialogOpen(false);
      handleCloseDialog();
      setIsLoading(false);
      setShowAlert(true);
      setTimeout(() => {
        setAlertMsg('');
        setShowAlert(false);
      }, 3000);
    }

  }

  return (
    <>
      <BillingDialog
        isOpenBilling={isOpenBilling}
        planName={planName}
        value={value}
        setCode={setCode}
        prevPlan={currentPlan?.plan?.name}
        plan={selectedPlan ? selectedPlan : currentPlan}
        cost={currentPlan?.cost}
        isActiveCode={activeCode}
        lastPrice={newPrice}
        code={code}
        setNewPlan={setNewPlan}
        setIsChanged={setIsChanged}
        userToken={userToken}
        onClose={handleCloseBillingDialog}
        handleCloseBillingDialog={handleCloseBillingDialog}
        subscriptionId={subscriptionId}
      />
      <Dialog open={isOpen} onClose={onClose} >
        <Box className={classes.dialogHeader}>
          <Typography variant="subtitle1" className={classes.title}>
            Manage Plans
          </Typography>

          <CloseIcon
            className={classes.closeIcon}
            onClick={() => {
              handleCloseDialog();
              setNewPrice(currentPlan?.cost);
              setCode('');
              setIsChangedPlan(false);
              setSelectedPlan(undefined);
            }}
          />
        </Box>
        <Box className={classes.formOne}>
          <Typography>
            {currentPlan?.plan?.name === 'Free'
              ? `Upgrade your plan `
              : `Your current Plan `}
            <span className={classes.highlitText}>
              ({currentPlan?.plan?.name})
            </span>
          </Typography>
          <Box className={classes.radioContainer}>
            <Box className={classes.radioButtonLeft}>
              <Radio
                classes={{ root: classes.radio, checked: classes.checked }}
                checked={value === 'Monthly'}
                onChange={handleChangeRadioButton}
                value="Monthly"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
                disabled={currentPlan?.plan?.name !== 'Free'}
              />
              <Typography>Monthly</Typography>
            </Box>
            <Box className={classes.radioButtonRight}>
              <Radio
                classes={{ root: classes.radio, checked: classes.checked }}
                checked={value === 'Annual'}
                onChange={handleChangeRadioButton}
                value="Annual"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
                disabled={currentPlan?.plan?.name !== 'Free'}
              />
              <Typography>Annual</Typography>
            </Box>
          </Box>
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Plan name*
            </InputLabel>

            <Select
              fullWidth
              variant="outlined"
              labelId="demo-simple-select-outlined-label"
              label="Plan name*"
              placeholder="Plan`s name"
              id="demo-simple-select-outlined"
              onChange={handleChange}
              name={planName}
              defaultValue="none"
            >
              <MenuItem value="none" disabled>
                {currentPlan?.plan?.name + ' '} (${currentPlan?.cost})
              </MenuItem>
              {value === 'Monthly'
                ? monthlyPlans.length > 0
                  ? monthlyPlans?.map((plan: any) => {
                    if (currentPlan?.plan?.name !== plan.plan.name) {
                      return (
                        <MenuItem
                          key={plan.plan.id}
                          value={plan.plan.name}
                          onClick={() => {
                            setSelectedPlan(plan);
                            setNewPrice(plan?.cost);
                          }}
                        >
                          {plan?.plan?.name + ' '}(${plan?.cost})
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })
                  : null
                : annualPlans.length > 0
                  ? annualPlans?.map((plan: any) => {
                    if (currentPlan?.plan?.name !== plan.plan.name) {
                      return (
                        <MenuItem
                          key={plan.plan.id}
                          value={plan.plan.name}
                          onClick={() => {
                            setSelectedPlan(plan);
                            setNewPrice(plan?.cost);
                          }}
                        >
                          {plan?.plan?.name + ' '}(${plan?.cost})
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })
                  : null}
            </Select>
          </FormControl>
          <TextField
            InputProps={{
              endAdornment:
                code.length > 0 ? (
                  activeCode ? (
                    <InputAdornment position="start">
                      <CheckCircleIcon color="secondary" />
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="start">
                      <ErrorIcon className={classes.error} />
                    </InputAdornment>
                  )
                ) : null
            }}
            className={classes.codeText}
            variant="outlined"
            label="Promotional code"
            margin="normal"
            fullWidth
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />
          <Typography variant="h5" className={classes.discount}>
            Add your promotional code and get a discount on your subscription
          </Typography>
          <Typography variant="h3" className={classes.total}>
            Total fee with the promotional code:
            <span className={classes.highlitText}>
              {' '}
              $
              {newPrice === 0 && selectedPlan?.plan.name !== 'Free'
                ? currentPlan?.cost
                : newPrice}
            </span>
          </Typography>
          {currentPlan?.plan?.id !== 1 && <Button
            variant='contained'
            className={classes.cancelMembershipButton}
            onClick={() => setIsDialogOpen(true)}>
            Cancel membership
          </Button>}
          <Button
            variant="contained"
            color="secondary"
            className={classes.renewButton}
            onClick={() => {
              if (planName === '') {
                setPlanName(currentPlan?.plan?.name);
                setId(currentPlan?.braintree_plan_id);
              }
              handleCloseDialog();
              handleOpenBillingInfo();
            }}
            disabled={false}
          >
            {currentPlan?.plan?.name === 'Free' ? 'Upgrade' : 'Renew'}
          </Button>
        </Box>
      </Dialog>
      <DeleteDialog
        isOpen={isDialogOpen}
        title={'Cancel membership'}
        firstOption={'Yes'}
        secondOption={'No'}
        handleOptionOne={handleCancelMembership}
        handleOptionTwo={() => setIsDialogOpen(false)}
        onClose={() => setIsDialogOpen(false)}
        disabled={isLoading}
        height={174}
        width={390}
      >
        Are you sure you want cancel your membership?
      </DeleteDialog>
      <Snackbar open={showAlert} color={alertType}>
        <Alert onClose={() => setShowAlert(false)} severity={alertType as Color}>
          {alertMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UpgradeDialog;
