import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  Button,
  InputAdornment,
  IconButton,
  Dialog,
  CircularProgress
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import useStyles from '../../../styles/globalStyles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { match } from 'react-router-dom';
import history from '../../../utils/history';
import * as api from '../../../services/apiService';
import Alert from '@material-ui/lab/Alert';
import { getUser } from '../../../utils';
import { PasswordStatus } from '../../../types';
import { VoiceChatRounded } from '@material-ui/icons';
import { strongPassword } from '../../../utils/validationConfig';

const ResetPassword = ({ match }: { match: match<any> }) => {
  const classes = useStyles();
  const email: string = match ? match.params.email : '';
  const [values, setValues] = useState<PasswordStatus>({
    password: '',
    showPassword: false
  });
  const [open, setOpen] = React.useState<boolean>(false);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [sent, setSent] = React.useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  let resetSchema = yup.object().shape({
    new_password: yup
      .string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!.%*+=(){}?&,:;'-])[A-Za-z\d@$#!.%+=(){}*?&,:;'-]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Number and one special case Character'
      ),
    verification_code: yup.string().required('Verification code is required')
  });

  const { register, handleSubmit, errors, getValues, watch } = useForm({
    resolver: yupResolver(resetSchema)
  });
  const watchFields = watch('new_password');
  const onSubmit = async (values: any) => {
    values.email = email;
    setOpen(true);
    const { status_code: statusCode } = await api.resetPassword(values);
    if (statusCode === api.status.SUCCESS) {
      setShowMessage(true);
      history.push('/signin');
    } else if (statusCode === api.status.INVALID_VERIFICATION_CODE) {
      setShowError(true);
    }
    handleDialogClose();
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const resendVerifyLink = React.useCallback(async () => {
    const { status_code: statusCode } = await api.forgetPassword({ email });
    if (statusCode === api.status.SUCCESS) {
      setSent(true);
    }
  }, []);

  const handleChange = (prop: any) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          className={classes.showPassword}
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {values.showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    )
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleDialogClose}

      >
        <CircularProgress className={classes.indicator} disableShrink />
      </Dialog>
      {showMessage ? (
        <Box mb={2}>
          <Alert severity="success">Email has been sent.</Alert>
        </Box>
      ) : null}

      <Box>
        <Grid
          className={classes.boxSignIn}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={5} className={classes.bigBox}>
            <Paper variant="outlined" className={classes.paperSignIn}>
              <Box mb={3}>
                <Typography variant="subtitle1">Reset Password</Typography>
              </Box>
              {showError ? (
                <Box mt={2}>
                  <Alert severity="error">
                    Invalid verification code! Please try again.
                  </Alert>
                </Box>
              ) : null}
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.container}
              >
                <Typography className={classes.textLeft} variant="caption">
                  Please enter the code we sent to your email address{' '}
                  <span className={classes.email}>“{email}”</span>, to reset
                  your password
                </Typography>
                <TextField
                  name="verification_code"
                  inputRef={register}
                  label="Verification code"
                  className={classes.textField}
                  margin="normal"
                  helperText={
                    errors.verification_code && errors.verification_code.message
                  }
                  fullWidth
                  error={errors.verification_code ? true : false}
                />
                <TextField
                  InputProps={inputProps}
                  name="new_password"
                  type={values.showPassword ? 'text' : 'password'}
                  inputRef={register}
                  label="New Password"
                  className={classes.textField}
                  margin="normal"
                  fullWidth
                  helperText={
                    errors.new_password && errors.new_password.message
                  }
                  error={errors.new_password ? true : false}
                />{' '}
                <Box className={classes.PaswordStrength}>
                  {watchFields && !errors.new_password ? (
                    getValues('new_password').length < 6 ? (
                      <Box>
                        <Box className={classes.weakPassword}>
                          Weak password
                        </Box>
                      </Box>
                    ) : strongPassword.test(getValues('new_password')) ? (
                      <Box className={classes.strongPassword}>
                        Strong password
                      </Box>
                    ) : (
                      <Box className={classes.normalPassword}>
                        Normal password
                      </Box>
                    )
                  ) : null}
                </Box>
                <a
                  className={classes.resendLink}
                  onClick={() => resendVerifyLink()}
                >
                  Re-send verification code
                </a>
                <Button
                  className={classes.highlightButtonreset}
                  variant="contained"
                  color="primary"
                  aria-label="delete"
                  type="submit"
                >
                  Reset Password
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ResetPassword;
