import { makeStyles, Theme, createStyles } from '@material-ui/core';

const PaymentStyle = makeStyles((theme: Theme) =>
  createStyles({
    dialogHeader: {
      marginTop: 10,
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 22
    },
    title: {
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 500,
      marginTop: 13,
      marginBottom: 15,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    closeIcon: {
      marginRight: 10,
      color: '#aaaaaa',
      cursor: 'pointer'
    },
    subscribeButton: {
      width: '100%'
    },
    buttonContainer: {
      display: 'flex',
      width: '100%'
    },
    formOne: {
      margin: '0px 40px 30px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 10px 30px'
      }
    },
    formBilling: {
      margin: '0px 40px 30px',
      display: 'flex',

      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        margin: '0px 10px 30px'
      },
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    headerBillingInfo: {
      textAlign: 'center',
      marginBottom: 30
    },
    highlitText: {
      color: '#ffad2c',
      fontSize: 14
    },
    beforeDisscount: {
      fontSize: 14,
      textDecoration: 'line-through'
    },
    payment: {
      [theme.breakpoints.up('sm')]: {
        width: 400
      }
    },
    BillingInfoHeader: {
      border: '1px solid rgba(170, 170, 170, 0.5)',
      padding: 20,
      position: 'relative',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: 7
      }
    },
    radioContainer: {
      display: 'flex',
      marginTop: 30
    },
    radioButtonLeft: {
      display: 'flex',
      alignItems: 'center',
      width: '50%',
      border: '1px solid rgba(170, 170, 170, 0.8)',
      marginRight: 3
    },
    radio: {
      '&$checked': {
        color: '#ffad2c'
      }
    },
    checked: {},
    radioButtonRight: {
      display: 'flex',
      alignItems: 'center',
      width: '50%',
      border: '1px solid rgba(170, 170, 170, 0.8)',
      marginLeft: 3
    },

    planImage: {
      position: 'absolute',
      top: -1,
      right: 20
    },
    inline: {
      display: 'inline'
    },
    planName: {
      fontWeight: 500
    },
    shortcut: {
      paddingLeft: 6
    },
    price: {
      marginBottom: 5,
      marginTop: 5
    },
    pay: {
      textAlign: 'center',
      margin: '20px 0px 12px'
    },
    footerBillingInfo: {
      textAlign: 'center',
      marginBottom: 20
    },
    error: {
      color: '#ff3b30'
    },
    codeText: {
      marginTop: 0
    },
    discount: {
      margin: '5px 0px 24px'
    },
    total: {
      marginBottom: 24
    },
    renewButton: {
      width: '100%'
    },
    cancelMembershipButton: {
      width: '100%',
      marginBottom: 10,
      backgroundColor: '#dc3545',
      color: 'white'
    },
    formControl: {
      width: '100%',
      marginTop: 24,
      marginBottom: 12
    }
  })
);

export default PaymentStyle;
