import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Paper,
  Snackbar,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import paymentImg from '../../../assets/PromotionalSite/Plans/payment.svg';
import Free from '../../../assets/PromotionalSite/Plans/FDark.svg';
import Moderate from '../../../assets/PromotionalSite/Plans/MDark.svg';
import Busy from '../../../assets/PromotionalSite/Plans/BDark.svg';
import Unlimited from '../../../assets/PromotionalSite/Plans/UDark.svg';
import UpgradeDialog from '../../../components/Dialogs/UpgradeDialog';
import CurrentPlanStyle from './Styles';
import * as api from '../../../services/apiService';
import { Alert } from '@material-ui/lab';
var dateFormat = require('dateformat');

const CurrentPlan = () => {
  const [isOpen, setisOpen] = useState<boolean>(false);
  const [planName, setPlanName] = useState<string>('');
  const [pendingPlanName, setPendingPlanName] = useState<string>('');
  const [userToken, setUserToken] = useState<string>('');
  const [openLoader, setOpenLoader] = React.useState<boolean>(false);
  const [tokenError, setTokenError] = React.useState<boolean>(false);
  const [messageError, setMessageError] = React.useState<string>('');
  const [showError, setShowError] = React.useState<boolean>(false);
  const [currentPlan, setCurrentPlan] = useState<any>();
  const [newPlan, setNewPlan] = React.useState<boolean>(false);
  const [isPendingPlan, setIsPendingPlan] = React.useState<boolean>(false);
  const [startDateDay, setStartDateDay] = React.useState<string>('');
  const [code, setCode] = useState<string>('');

  const classes = CurrentPlanStyle();

  const handleCloseDialog = () => {
    setisOpen(false);
  };

  const handleChange = (event) => {
    setPlanName(event.target.value);
  };
  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleLoaderClose = () => {
    setOpenLoader(false);
  };

  const getPindingSubscription = React.useCallback(async () => {
    setOpenLoader(true);
    const {
      data,
      status_code: statusCode
    } = await api.getPindingSubscription();
    if (statusCode === api.status.SUCCESS) {
      if (data?.next_plan !== null) {
        setPendingPlanName(data?.next_plan);
        setStartDateDay(
          dateFormat(new Date(data?.next_billing_date), 'dd mmm yy')
        );
        setIsPendingPlan(true);
      }
    }
    handleLoaderClose();
  }, []);

  const getCurrentPlan = React.useCallback(async () => {
    setOpenLoader(true);
    const { data, status_code: statusCode } = await api.getCurrentPlan();
    if (statusCode === api.status.SUCCESS) {
      setCurrentPlan(data);
      setTokenError(data?.is_subscription_cancelled);
      setMessageError(
        "You can't manage your plan since you have cancelled subscription"
      );
    }
    handleLoaderClose();
  }, []);

  const getUserToken = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getUserToken();
    setNewPlan(false);
    if (statusCode === api.status.SUCCESS) {
      setUserToken(data.token);
    }
  }, []);

  useEffect(() => {
    getUserToken();
    getPindingSubscription();
  }, []);

  useEffect(() => {}, [isPendingPlan]);

  useEffect(() => {
    if (newPlan) {
      getCurrentPlan();
      getUserToken();
      getPindingSubscription();
    }
  }, [newPlan, getCurrentPlan, getUserToken]);

  useEffect(() => {
    getCurrentPlan();
  }, [getCurrentPlan]);

  useEffect(() => {}, [currentPlan]);

  return (
    <div className={classes.root}>
      <Snackbar open={showError} color="warning">
        <Alert onClose={() => setShowError(false)} severity="warning">
          {messageError}
        </Alert>
      </Snackbar>
      <UpgradeDialog
        isOpen={isOpen && !tokenError}
        onClose={() => setisOpen(false)}
        handleChange={handleChange}
        handleCloseDialog={handleCloseDialog}
        userToken={userToken}
        setNewPlan={setNewPlan}
        subscriptionId={currentPlan?.subscr_id}
        getPendingSubscription={getPindingSubscription}
      />

      <Grid
        item={true}
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.headerContainer}
      >
        <Dialog open={openLoader} onClose={handleLoaderClose}>
          <CircularProgress className={classes.indicator} />
        </Dialog>
        <>
          <Grid
            item={true}
            xs={8}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            className={classes.currentPlan}
          >
            <NavLink className={classes.link} to="/profile">
              <Typography variant="h1" className={classes.inline}>
                {'Profile > '}
              </Typography>
            </NavLink>
            <Typography
              color="primary"
              variant="body2"
              className={classes.inline}
            >
              {'Current Plan'}
            </Typography>
          </Grid>
          <Grid
            item={true}
            xs={6}
            sm={6}
            md={6}
            lg={2}
            xl={2}
            className={classes.headerButton}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (!tokenError) {
                  setisOpen(true);
                } else {
                  setShowError(true);
                }
              }}
              startIcon={<img src={paymentImg} alt="payment" />}
            >
              Manage plans
            </Button>
          </Grid>
        </>
      </Grid>
      <Grid container item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          item={true}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.planHeaderContainer}
        >
          <Paper className={classes.planHeader}>
            <img
              src={
                currentPlan?.plan?.name === 'Unlimited'
                  ? Unlimited
                  : currentPlan?.plan?.name === 'Moderate'
                  ? Moderate
                  : currentPlan?.plan?.name === 'Busy'
                  ? Busy
                  : currentPlan?.plan?.name === 'Free'
                  ? Free
                  : Free
              }
              alt="planName"
              className={classes.planImage}
            />
            <Typography
              variant="subtitle1"
              className={[classes.inline, classes.planName].join(' ')}
            >
              {currentPlan?.plan?.name}
            </Typography>
            <Typography
              color="secondary"
              className={[classes.inline, classes.shortcut].join(' ')}
            >
              {currentPlan?.plan?.name === 'Busy' ? '(Most popular)' : ''}
            </Typography>
            <div className={isPendingPlan ? classes.newPlan : ''}>
              <Typography variant="subtitle1">
                {currentPlan
                  ? `$${currentPlan?.cost}/${currentPlan?.period}`
                  : null}
              </Typography>
              {isPendingPlan ? (
                <Typography variant="h3" className={classes.inline}>
                  <span>Your plan will be downgraded to</span>
                  <span className={classes.newPlanName}>
                    {' '}
                    {`(${pendingPlanName}) `}
                  </span>
                  {`starting from  ${startDateDay}`}
                </Typography>
              ) : null}
            </div>
          </Paper>
        </Grid>

        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box className={classes.planFeaturesContainer}>
            <Typography variant="h2" className={classes.planInfoFirstCel}>
              Plan Features
            </Typography>
            <Typography variant="h6" className={classes.planInfoCel}>
              {currentPlan?.features?.cases_per_month_limit === 0
                ? 'No cases'
                : currentPlan?.features?.cases_per_month_limit === 10000000
                ? `Unlimited cases per month`
                : currentPlan?.features?.cases_per_month_limit >= 1
                ? `Up to ${currentPlan?.features?.cases_per_month_limit} cases`
                : 'No cases'}
            </Typography>
            <Typography variant="h6" className={classes.planInfoCel}>
              {currentPlan?.plan?.name === 'Free'
                ? 'No images'
                : 'Unlimited patients images'}
            </Typography>
            <Typography variant="h6" className={classes.planInfoCel}>
              {currentPlan?.features?.registries_limit === 0
                ? 'No registries'
                : currentPlan?.features?.registries_limit === 1000000
                ? `Unlimited registry per month`
                : currentPlan?.features?.registries_limit >= 1
                ? `Up to ${currentPlan?.features?.registries_limit} registries`
                : 'No registries'}
            </Typography>
            <Typography variant="h6" className={classes.planInfoLastCel}>
              {currentPlan?.features?.automated_analysis}
            </Typography>
          </Box>
        </Grid>

        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box>
            <Typography
              variant="h2"
              className={classes.planUsedFeaturesFirstCel}
            >
              Used Features
            </Typography>
            <Typography variant="h6" className={classes.planUsedFeaturesCel}>
              {currentPlan?.features?.used_cases} cases added
            </Typography>
            <Typography variant="h6" className={classes.planUsedFeaturesCel}>
              {currentPlan?.features?.uploaded_images} patient images added
            </Typography>
            <Typography variant="h6" className={classes.planUsedFeaturesCel}>
              {currentPlan?.features?.used_registries} registries created
            </Typography>
            <Typography
              variant="h6"
              className={classes.planUsedFeaturesLastCel}
            >
              {currentPlan?.features?.used_automated_analysis}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default CurrentPlan;
